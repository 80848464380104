import imageOne from '../../assets/img-01.jpg';
import imageTwo from '../../assets/img-02.jpg';
import imageThree from '../../assets/img-03.jpg';
import imageFour from '../../assets/img-04.jpg';
import imageFive from '../../assets/img-05.jpg';
import imageSix from '../../assets/img-06.jpg';

export const firstImages = [
  {
    img: imageOne,
  },
  {
    img: imageTwo,
  },
  {
    img: imageThree,
  },
];

export const secondImages = [
  {
    img: imageFour,
  },
  {
    img: imageFive,
  },
  {
    img: imageSix,
  },
];
