import one from '../../assets/partners/2.png';
import two from '../../assets/partners/3.png';
import three from '../../assets/partners/4.png';
import four from '../../assets/partners/5.png';
import five from '../../assets/partners/6.png';
import six from '../../assets/partners/7.png';
import seven from '../../assets/partners/8.png';
import eight from '../../assets/partners/9.png';
import nine from '../../assets/partners/10.png';
import ten from '../../assets/partners/12.png';
import eleven from '../../assets/partners/13.png';

export const images = [
  {
    img: one,
  },
  {
    img: two,
  },
  {
    img: three,
  },
  {
    img: four,
  },
  {
    img: five,
  },
  {
    img: six,
  },
  {
    img: seven,
  },
  {
    img: eight,
  },
  {
    img: nine,
  },
  {
    img: ten,
  },
  {
    img: eleven,
  },
];
